<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('TRANSLATIONS.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button @click="ImportExportTranslations()" id="btn_ImportExport" size="small" class="ml-2" color="primary" type="filled" icon="import_export"></vs-button>
            <vs-button @click="LoadData(section_fk)" size="small" class="ml-2" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('TRANSLATIONS.HEADER.TEXT')}}</span>
        </p>
        <vs-popup fullscreen :title="$t('TRANSLATIONS.POPUP.HEADER')" :active.sync="popupActive" >
          <vs-tabs>
            <vs-tab label="Import">
              <vue-dropzone id="translationDrop" ref="translationDrop" :options="dropOptions" class="mb-3" v-on:vdropzone-success="dropzoneSuccess" v-on:vdropzone-error="dropzoneError"></vue-dropzone>
              <div class="d-flex vs-con-loading__container">
                <vs-button :disabled="!tempTransAvailable" id="acceptTempBtn" @click="ImportTempTranslations()" color="success" type="filled" icon="done">{{$t('TRANSLATIONS.POPUP.BUTTON.ACCEPT')}}</vs-button>
                <vs-button :disabled="!tempTransAvailable" id="dropTempBtn" @click="DropTempTranslations()" class="ml-3" color="danger" type="filled" icon="clear">{{$t('TRANSLATIONS.POPUP.BUTTON.DROP')}}</vs-button>
              </div>
              <div id="divTranslationsTemp_table" class="vs-con-loading__container mt-3">
                <vs-table
                  search
                  :data="tempTranslations"
                  :noDataText="$t('TRANSLATIONS.POPUP.TABLE.NODATA') "
                  stripe
                  max-items="100"
                  pagination
                  hoverFlat
                  id="translationsTemp_table">

                  <template slot="header">
                    <h3>
                      {{$t('TRANSLATIONS.POPUP.TABLE.HEADER')}}
                    </h3>
                  </template>
                  <template slot="thead" >
                    <vs-th > 
                    </vs-th>
                    <template v-for="header in tempTranslationsHeader">             
                      <vs-th :key="header.language + '-langtmpHead'" :sort-key="header.text">
                        {{header.text}}
                      </vs-th>  
                    </template>           
                  </template>

                  <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                        <vs-td :data="tr.checked">
                          <vs-checkbox size="small" v-model="tr.checked"></vs-checkbox>
                        </vs-td> 
                        <template v-for="header in tempTranslationsHeader">
                          <vs-td :key="header.language" :data="tr[header.language]">
                          {{tr[header.language]}}
                          <template slot="edit">
                            <vs-input v-model="tr[header.language]" class="inputx" />
                          </template>
                          </vs-td>    
                        </template>

                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </vs-tab>
            <vs-tab label="Export" >
              <div class="d-flex mb-3 mt-3 ">
                <vs-radio v-model="exporttype" vs-name="exporttype" vs-value="0" class="mr-3">CSV</vs-radio>
                <vs-radio v-model="exporttype" vs-name="exporttype" vs-value="1" class="mr-3">XML</vs-radio>
              </div>
              <vs-button class="vs-con-loading__container" id="exportlangBtn" @click="ExportTranslations()" color="primary" type="filled">{{$t('TRANSLATIONS.POPUP.BUTTON.EXPORT')}}</vs-button>
            </vs-tab>
          </vs-tabs>
        </vs-popup>

        <div class="default-input d-flex align-items-center">
          <div v-if="!newSectionActive">
          <vs-select 
            :label="$t('TRANSLATIONS.HEADER.LABEL.SECTION')"
            class="mb-3"
            v-model="section_fk" autocomplete>
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in sections"/>
            <vs-button class="mt-2 fullwidth" size="small" color="primary" type="border" icon="add" @click="ToggleNewSectionActive()">{{$t('TRANSLATIONS.HEADER.BUTTON.NEW_SECTION')}}</vs-button>
          </vs-select>
          </div>
          <div v-if="newSectionActive">
            <vs-input :label="$t('TRANSLATIONS.HEADER.INPUT.NEW_SECTION')"  class="inputx mb-3" :placeholder="$t('TRANSLATIONS.HEADER.INPUT.NEW_SECTION')"  v-model="newSectionName"/>
            <vs-button class="" size="medium" color="primary" type="filled" @click="InsertSection()" >{{$t('COM.SAVE')}}</vs-button>
            <vs-button class="ml-3" size="medium" color="primary" type="border" @click="newSectionActive = false">{{$t('COM.CANCEL')}}</vs-button>
          </div>
          
        </div>
        <h5>{{$t('TRANSLATIONS.HEADER.HEADER.NEW_TRANSLATION')}}</h5>
        <div class="default-input d-flex align-items-center">
          
          <vs-input :disabled="true" :label="$t('TRANSLATIONS.HEADER.INPUT.ACT_SECTION')"  class="inputx" :placeholder="$t('TRANSLATIONS.HEADER.INPUT.ACT_SECTION')"  v-model="getActSectionName"/>
          <vs-input :label="$t('TRANSLATIONS.HEADER.INPUT.NEW_PLACEHOLDER')"  class="inputx ml-3" :placeholder="$t('TRANSLATIONS.HEADER.INPUT.NEW_PLACEHOLDER')"  v-model="newPlaceholderUPPER"/>
          <vs-input :label="$t('TRANSLATIONS.HEADER.INPUT.NEW_PLACEHOLDER_DESCR')"  class="inputx ml-3" :placeholder="$t('TRANSLATIONS.HEADER.INPUT.NEW_PLACEHOLDER_DESCR')"  v-model="newPlaceholderDescription"/>         
          <vs-input :label="$t('TRANSLATIONS.HEADER.INPUT.NEW_TRANSLATION')"  class="inputx ml-3" :placeholder="$t('TRANSLATIONS.HEADER.INPUT.NEW_TRANSLATION')"  v-model="newTranslation"/>
          <vs-button :disabled="newPlaceholderUPPER.length == 0 || newTranslation.length == 0" class="mt-3 ml-3" size="medium" color="primary" type="filled" icon="add" @click="InsertTranslation()">{{$t('TRANSLATIONS.HEADER.BUTTON.NEW_TRANSLATION')}}</vs-button>
        </div>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card >
        <div id="translations_table" class="vs-con-loading__container">
        <vs-table
          search
          :data="translations"
          :noDataText="$t('TRANSLATIONS.TABLE.NODATA') "
          stripe
          max-items="25" 
          pagination
          hoverFlat
          v-model="selected"
          @selected="handleSelected">

          <template slot="header">
            <h3>
              {{$t('TRANSLATIONS.TABLE.HEADER')}}
            </h3>
          </template>
          <template slot="thead" v-for="header in translationHeader">              
            <vs-th :key="header.language + '-langHead'" :sort-key="header.text">
              {{header.text}}
            </vs-th>          
          </template>

          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                <template v-for="header in translationHeader">
                  <vs-td :key="header.language" :data="tr[header.language]">
                  {{tr[header.language]}}
                  </vs-td>    
                </template>
            </vs-tr>
          </template>
        </vs-table>
        </div>
        </vs-card>
      </vs-col>
  </vs-row>
  <vs-prompt
    color="primary"
    @accept="SaveTranslations"
    :title="$t('TRANSLATIONS.EDIT.PROMPT_TITLE')"
    :acceptText="$t('COM.SAVE')"
    id="editGroupDlg"
    :cancelText="$t('COM.CANCEL')"
    :active.sync="editTranslationsActiv">
        <div class="con-exemple-prompt">
            <div class="">
                <h5>{{$t('TRANSLATIONS.EDIT.HEADER')}}</h5>
                <h6 class="mt-2 mb-3">{{selectedNew.lang_0}}</h6>

                <template v-for="lang in translationHeader">
                  <vs-input v-if="lang.language != 'lang_0'" :key="lang.language + '-edit'" :label="lang.text"  class="inputx mb-2 fullwidth" :placeholder="lang.text" v-model="selectedNew[lang.language]" />
                </template>
                <div class="d-flex">
                  <vs-button size="small" color="primary" type="filled" icon="delete" @click="OpenConfirmDeleteTranslation(selectedNew)">{{$t('TRANSLATIONS.DELETE')}}</vs-button>
                </div>
            </div>

        </div>
  </vs-prompt> 
</div>
</template>

<script>
import downloadMethods from '../helper/staticDownloadFuncHelper';
import vueDropzone from "vue2-dropzone";
import LocalStorageService from '../services/localstorage.service';

const localStorageService = LocalStorageService.getService();

export default {
  name: "Translations",
  components: {
    vueDropzone
  },
    data: function (){
      return {
      translations:[],
      selected:[],
      selectedNew:[],
      translationHeader:[],
      section_fk:0,
      newSectionName:"",
      newSectionActive:false,
      language_fk:1,
      newTranslation:"",
      newPlaceholderDescription:"",
      newPlaceholder:"",
      editTranslationsActiv:false,
      dropOptions: {
        url: this.$appConfig.apiBaseUrl+'/language/translations/upload/',
        maxFilesize: 1, // MB
        maxFiles: 1,
        chunking: false,
        chunkSize: 500, // Bytes
        thumbnailWidth: 150, // px
        thumbnailHeight: 150,
        addRemoveLinks: true,
        headers:{"Authorization":'Bearer ' + localStorageService.getAuthToken()},
        acceptedFiles:".csv",
        forceFallback:false,
        dictDefaultMessage:this.$t('DROPZONE.DEFAULTTEXT'),
        dictFallbackMessage: this.$t('DROPZONE.NOTSUPPORTED'),
        dictFallbackText: this.$t('DROPZONE.FALLBACK_TEXT'),
        dictFileTooBig:this.$t('DROPZONE.TOO_BIG'),
        dictInvalidFileType:this.$t('DROPZONE.INVALID_TYPE'),
        dictResponseError:this.$t('DROPZONE.RESPONSE_ERROR'),
        dictCancelUpload:this.$t('DROPZONE.CANCEL_UPLOAD'),
        dictUploadCanceled:this.$t('DROPZONE.UPLOAD_CANCELED'),
        dictCancelUploadConfirmation:this.$t('DROPZONE.CONFIRM_CANCEL'),
        dictRemoveFile:this.$t('DROPZONE.REMOVE_FILE'),
        dictRemoveFileConfirmation:this.$t('DROPZONE.CONFIRM_REMOVE_FILE'),
        dictMaxFilesExceeded:this.$t('DROPZONE.MAX_FILES')
      },
      popupActive:false,
      tempTranslations:[],
      tempTranslationsHeader:[],
      exporttype:0
      };
  },
  mounted () {

      if(this.sections.length > 0)
      {
        this.section_fk = this.sections[0].value;
      }

      this.LoadData(this.section_fk);
      // Liste für die Tabellenspalten zusammenstellen, der Platzhaltertext bekommt die sprache "0" damit beim Zusammenbauen der Tabelle einfach alle Sprachen von 0 - x durchlaufen werden müssen und alles da ist
      this.translationHeader = [];
      var val = {"text": "Platzhalter", "language":"lang_0"};
      this.translationHeader.push(val);
      if(this.$store.state.language.languages.data != null)
      {
        for (let i = 0; i < this.$store.state.language.languages.data.length; i++)
        {
          var txt = this.$store.state.language.languages.data[i].name_full + " ("+this.$store.state.language.languages.data[i].name_short+")";
          val = {"text": txt, "language":"lang_"+ this.$store.state.language.languages.data[i].id};
          this.translationHeader.push(val);
        }        
      }

      this.tempTranslationsHeader = [];
      var valTemp = {"text": "LineNr", "language":"lang_0"};
      this.tempTranslationsHeader.push(valTemp);
      valTemp = {"text": "Section", "language":"lang_1"};
      this.tempTranslationsHeader.push(valTemp);
      valTemp = {"text": "Platzhalter", "language":"lang_2"};
      this.tempTranslationsHeader.push(valTemp);     

      if(this.$store.state.language.languages.data != null)
      {
        for (let i = 0; i < this.$store.state.language.languages.data.length; i++)
        {
          var txtHeader = this.$store.state.language.languages.data[i].language_format;
          val = {"text": txtHeader, "language":"lang_"+ Number(this.$store.state.language.languages.data[i].id+2)};// um 2 verrutschen weil jetzt 3 fixe spalten vorher sind
          this.tempTranslationsHeader.push(val);
        }        
      }
    },
  computed: 
  {
    newPlaceholderUPPER: {
      get: function () {
      return this.newPlaceholder;
      },
      // setter
      set: function (newValue) {
        this.newPlaceholder = newValue.toUpperCase();
      }
    },
    tempTransAvailable(){
      return this.tempTranslations.length > 0;
    },
    getActSectionName(){
      var retVal = "";

      if(this.$store.state.language.sections.data != null)
      {
        for (let i = 0; i < this.$store.state.language.sections.data.length; i++)
        {
            if(this.$store.state.language.sections.data[i].id == this.section_fk)
            {
              retVal = this.$store.state.language.sections.data[i].section;
              break;
            }
        }        
      }

      return retVal;
    },
    getTableData() {
      var data = [];
      var retVal = [];
      if(this.$store.state.language.translationsForSection.data != null)
      {
        data = this.$store.state.language.translationsForSection.data;
      }
      for(var i=0; i < data.length;i++)
      {
        // Jede Tabellenzeile hat folgendes SCHEMA: {"language_fk": text, ... } wobei die Sprache "0" immer da ist und den Platzhaltertext beinhaltet
        var item = {"lang_0": data[i].placeholder + '#' + data[i].placeholder_fk};// Platzhalter als Sprache 0 einfügen
        // restliche Sprachen laut Sprachen und Reihenfolge im Tabellen-Header einsortieren
        this.translationHeader.forEach(element => {
          data[i].texts.forEach(text =>{
              if("lang_"+text.language_fk == element.language)
              {
                  item[element.language] = text.translation;
              }
          });
        });

        retVal.push(item);
      }
 
      return retVal;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.language.translationsForSection.status) != 'undefined')
        loading = this.$store.state.language.translationsForSection.status.loading;
      return loading;
    },    
    sections: function(){
      var ret = [];
      if(this.$store.state.language.sections.data != null)
      {
        for (let i = 0; i < this.$store.state.language.sections.data.length; i++)
        {
            var val = {"text": this.$store.state.language.sections.data[i].section, "value":this.$store.state.language.sections.data[i].id};
            ret.push(val);
        }        
      }
      return ret;
    },
    getTempTranslations(){
      var retVal = [];
      var data = [];
      if(this.$store.state.language.tempTranslations.data != null)
      {
        data = this.$store.state.language.tempTranslations.data;
      }
      for(var i=0; i < data.length;i++)
      {
        var trans = JSON.parse(data[i]);

        // Jede Tabellenzeile hat folgendes SCHEMA: {"language_fk": text, ... } wobei die Sprache "0" die LineNr, "1" die Section und "2" den Platzhalter darstellen, bei alle anderen ist die language_fk um 2 versetzt
        var item = {"checked":true,"lang_0": trans.lineNr,"lang_1": trans.section,"lang_2": trans.placeholder};
        // restliche Sprachen laut Sprachen und Reihenfolge im Tabellen-Header einsortieren
        this.tempTranslationsHeader.forEach(element => {
          trans.translations.forEach(text =>{
              if("lang_"+Number(text.language_fk+2) == element.language)
              {
                  item[element.language] = text.translation;
              }
          });
        });
        retVal.push(item);
      }
      return retVal;
    }
  },
  watch: {
      getTableData(value) {
          this.$data.translations = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#translations_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#translations_table'});
        }
      },
      section_fk(value)
      {
        this.LoadData(value);
      },
      getTempTranslations(value)
      {
        this.tempTranslations = value;
      }
    },
  methods: {
    ...downloadMethods,
      ExportTranslations(){
        this.$store.dispatch('alertqueue/showLoader', {'id':'#exportlangBtn', 'scale':0.4});
          
        this.$store.dispatch('language/exportTranslations',{ "type":Number(this.exporttype)})
        .then(response => {
            this.DownloadWithFileSaver(response);
            this.$store.dispatch('alertqueue/hideLoader', {'id':'#exportlangBtn'});
            this.popupActive=false;
          }
        );
      },
      handleSelected(tr){
        this.selectedNew = Object.assign({}, tr);
        this.editTranslationsActiv = true;
      },
      LoadTempTranslations(){
        this.$store.dispatch('alertqueue/showLoader', {'id':'#divTranslationsTemp_table'});
        this.$store.dispatch('language/getTempTranslations',{ root: true },{ root: true })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
            this.$store.dispatch('alertqueue/hideLoader', {'id':'#divTranslationsTemp_table'});
          }
        );
      },
      ImportExportTranslations(){
        this.popupActive = true;
        this.LoadTempTranslations();
      },
      DropTempTranslations(){
        this.$store.dispatch('alertqueue/showLoader', {'id':'#dropTempBtn', 'scale':0.4});
        this.$store.dispatch('language/DropTempTranslations',{ root: true },{ root: true })
        .then(response => {
          if(response.success == true)
          {
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('TRANSLATIONS.POPUP.SUCCESS.DROP_TEMP')}); 
            this.popupActive=false;
          }
          else
          {
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TRANSLATIONS.POPUP.ERROR.DROP_TEMP')}); 
          }
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#dropTempBtn'});
        }, 
        error =>{
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TRANSLATIONS.POPUP.ERROR.DROP_TEMP')+error});   
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#dropTempBtn'});
        });
      },
      ImportTempTranslations(){
        

        if(this.tempTranslations.length > 0 )
        {
          var dataToSend = [];
          this.tempTranslations.forEach(trans =>{
              if(trans.checked == true)
              {
                var textitems = [];
                var section = "";
                var placeholder = "";

                var entr = Object.entries(trans);
                for (const [name, val] of entr)
                {
                  
                  if(name.startsWith("lang_"))
                  {
                    var language_fk = Number(name.replace("lang_",""));

                    switch(language_fk)
                    {
                      case 0:
                        // Line nr - nix tun
                        break;
                      case 1:
                        section = val;
                        break;
                      case 2:
                        placeholder = val;
                        break;
                      default:
                        {
                          language_fk = language_fk - 2;
                          var item = {"language_fk":language_fk, "text":val};
                          textitems.push(item);
                          break;
                        }
                    }
                    
                  }
                  
                }
                var uploaditem = {"section": section, "placeholder":placeholder, "translations":textitems};
                dataToSend.push(uploaditem);
              }
          });
          if(dataToSend.length > 0)
          {
            this.$store.dispatch('alertqueue/showLoader', {'id':'#acceptTempBtn', 'scale':0.4});
            this.$store.dispatch('language/AcceptTempTranslations',dataToSend)
            .then(response => {
              if(response.success == true)
              {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('TRANSLATIONS.POPUP.SUCCESS.ACCEPT_TEMP')}); 
                this.popupActive=false;
              }
              else
              {
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TRANSLATIONS.POPUP.ERROR.ACCEPT_TEMP')}); 
              }
              this.$store.dispatch('alertqueue/hideLoader', {'id':'#acceptTempBtn'});
            }, 
            error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TRANSLATIONS.POPUP.ERROR.ACCEPT_TEMP')+error});   
              this.$store.dispatch('alertqueue/hideLoader', {'id':'#acceptTempBtn'});
            });
          }
          else
          {
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('TRANSLATIONS.POPUP.ACCEPT_TEMP.NO_DATA_SELECTED')});           
          }
        }
        else
        {
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TRANSLATIONS.POPUP.ACCEPT_TEMP.NO_DATA')}); 
        }
        
      },
      // eslint-disable-next-line no-unused-vars
      dropzoneSuccess(file, response){
        this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('TRANSLATIONS.SUCCESS.UPLOAD_TRANSLATIONS').replace("*1*",file.upload.filename)});
        this.$refs.translationDrop.removeFile(file);
        this.LoadTempTranslations();

      },
      // eslint-disable-next-line no-unused-vars
      dropzoneError(file, message, xhr){
        this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TRANSLATIONS.ERROR.UPLOAD_TRANSLATIONS').replace("*1*",file.upload.filename)});
        this.$refs.translationDrop.removeFile(file);
      },
      SaveTranslations(){
        var data = [];
        // Änderungen einzeln übernehmen, so werden sie auch im Grid aktualisiert
        for(var i = 0; i < this.translationHeader.length; i++)
        {
          var field =this.translationHeader[i].language;

          if(typeof(this.selectedNew[field]) != 'undefined')
          {
            this.selected[field] = this.selectedNew[field];
          }
          else
          {
            this.selected[field] = "";
          }

        }
        const keys = Object.keys(this.selected);
        const values = Object.values(this.selected);

        var placeholder_fk = 0;
        for(var j = 0; j < keys.length;j++)
        {
            var lang = keys[j].replace("lang_","");
            var lang_fk = parseInt(lang);

            var item = {language_fk:lang_fk,translation:values[j]};
            

            if(lang_fk == 0)
            {
              // Aus Sprache 0 die placholder id filtern und nicht übergeben
              var pl = values[j];
              var parts = pl.split("#");
              if(parts.length == 2)
              {
                placeholder_fk = Number(parts[1]);
              }
            }
            else
            {
              // restlichen sprachen übergeben
              data.push(item);
            }
        }

        var request = {texts:data,placeholder_fk:placeholder_fk,section_fk:this.section_fk};

        this.$store.dispatch('language/updateTranslations', request)
        .then(response => {
          var fail = true;
          if(response != null)
          {
            if(response.success == true)
            {
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('TRANSLATIONS.SUCCESS.UPDATE_TRANSLATIONS')}); 
              fail = false;
            }
          }
          if(fail == true)
          {
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TRANSLATIONS.ERROR.UPDATE_TRANSLATIONS')}); 
            this.LoadData(this.section_fk); 
          }
        }, 
        error =>{
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TRANSLATIONS.ERROR.UPDATE_TRANSLATIONS')+error});   
          this.LoadData(this.section_fk);
        });
      },
      LoadData: function (section_fk)
      {
        this.$store.dispatch('language/getTranslationsForSection', {language_fk:-1,section_fk:section_fk}); 
      },
      ToggleNewSectionActive()
      {
        this.newSectionActive = !this.newSectionActive;
      },
      InsertSection(){
        this.$store.dispatch('language/insertSection', this.newSectionName)
        .then(response => {
          if(response.success == true)
          {
            this.$store.dispatch('language/getSections', { root: true },{ root: true });
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('TRANSLATIONS.SUCCESS.INSERT_SECTION')}); 
          }
        }, 
        error =>{
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TRANSLATIONS.ERROR.INSERT_SECTION')+error});   
        });

        this.newSectionActive = false;
      },
      InsertTranslation(){

        var data = {section_fk:this.section_fk,placeholder:this.newPlaceholder,translation:this.newTranslation,language_fk:this.language_fk,placeholder_description:this.newPlaceholderDescription};

         this.$store.dispatch('language/insertTranslation', data)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData(this.section_fk);
            this.newPlaceholder = "";
            this.newTranslation = "";
            this.newPlaceholderDescription = "";
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('TRANSLATIONS.SUCCESS.INSERT_TRANSLATION')}); 
          }
          else
          {
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TRANSLATIONS.ERROR.INSERT_TRANSLATION')}); 
          }
        }, 
        error =>{
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TRANSLATIONS.ERROR.INSERT_TRANSLATION')+error});   
        });
      },
      OpenConfirmDeleteTranslation(data)
      {
        this.editTranslationsActiv = false;
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('TRANSLATIONS.QUESTION.DELETE').replace("*1*",data.lang_0),
          accept: this.SendDeleteTranslationRequest,
          parameters:{"id": data.lang_0,"name": data.lang_0}
        });
      },
      SendDeleteTranslationRequest: function(parameters)
      {
        this.$store.dispatch('language/deleteTranslation', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.RefreshData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('TRANSLATIONS.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('TRANSLATIONS.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TRANSLATIONS.ERROR.DELETE').replace("*1*",error)});  
        });
      },
  }
};

</script>
<style>
.fullwidth
{
  width: 100%;
}
div#translationsTemp_table > div > div > table > tr > td 
{
  padding-top:0;
  padding-bottom:0;
}
</style>